import { Box } from '@mui/material';

import { addShape } from '../../../redux/slices/studio';
import { useDispatch, useSelector } from '../../../redux/store';

export default function ClipartSection() {
  const dispatch = useDispatch();
  const canvasId = useSelector((state) => state.studio.selectedView.type);

  return (
    <Box padding={2}>
      <Box
        onClick={() => {
          dispatch(
            addShape({
              canvasId,
              shape: {
                id: Date.now().toString(),
                type: 'square',
                x: 20,
                y: 20,
                width: 50,
                height: 50,
                fill: 'green',
                stroke: 'black',
                strokeWidth: 4,
              },
            })
          );
        }}
        sx={{
          width: 50,
          height: 50,
          backgroundColor: 'green',
          border: '4px solid green',
          borderRadius: 0,
        }}
      />
      <Box
        onClick={() => {
          dispatch(
            addShape({
              canvasId,
              shape: {
                id: Date.now().toString(),
                type: 'circle',
                x: 40,
                y: 40,
                radius: 70,
                width: 50,
                height: 50,
                fill: 'green',
                stroke: 'black',
                strokeWidth: 4,
              },
            })
          );
        }}
        sx={{
          width: 50,
          height: 50,
          backgroundColor: 'green',
          border: '4px solid green',
          borderRadius: 50,
        }}
      />
    </Box>
  );
}
