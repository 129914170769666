import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';
import { designMockupsLiners, printfulProductsListeners } from './listners';
import { getStudioProduct } from './listners/get-studio-product';
import { pfProductSaverUpdaterListeners } from './listners/seve-update-pf-product';

// ----------------------------------------------------------------------
const persistedReducers = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  devtool: true,
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).prepend([
      printfulProductsListeners.middleware,
      designMockupsLiners.middleware,
      getStudioProduct.middleware,
      pfProductSaverUpdaterListeners.middleware,
    ]),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
