import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  product: null,
  variants: [],
  page: 1,
  itemsPerPage: 20,
  searchQuery: '',
};

export const slice = createSlice({
  name: 'pfProduct',
  initialState,
  reducers: {
    setPfProduct(state, action) {
      state.product = action.payload;
    },
    setPfVariants(state, action) {
      state.variants = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
  },
  selectors: {
    getPfProductsSelector(state) {
      const startIndex = (state.page - 1) * state.itemsPerPage;
      const endIndex = Math.min(startIndex + state.itemsPerPage, state?.product?.length);

      if (!state.searchQuery) return state.product?.slice(startIndex, endIndex);

      const filteredProducts = state.product.filter((pro) => {
        return pro.title.toLowerCase().includes(state.searchQuery.toLowerCase());
      });

      return filteredProducts.product?.slice(startIndex, endIndex);
    },
    getSearchQuerySelector(state) {
      return state.searchQuery;
    },
    getItemsPerPageSelector(state) {
      return state.itemsPerPage;
    },
    getPageSelector(state) {
      return state.page;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setPfProduct, setPfVariants, setPage, setSearchQuery } = slice.actions;
export const { getItemsPerPageSelector, getPageSelector, getSearchQuerySelector, getPfProductsSelector } =
  slice.selectors;
