import { createListenerMiddleware } from '@reduxjs/toolkit';
import { _onSave, onCreateOrUpdateProduct, slice as studioSlice } from '../slices/studio';
import { authSlice } from '../slices/auth';
import { Utils } from '../../utils';
import { client } from '../../graphql/Server';
import {
  GET_PRINTFUL_API,
  GET_PRINTFULL_STORE_ID,
  POST_PRINTFUL_API,
  PUT_PRINTFUL_REQ,
  SAVE_PRODUCT,
  UPDATE_PRODUCT_LOCAL,
} from '../../graphql/printful';
import { GET_PRODUCTS } from '../../graphql/product';
import { printful as printfulSlice } from '../slices/printful';

const pfProductSaverUpdaterListeners = createListenerMiddleware();

pfProductSaverUpdaterListeners.startListening({
  actionCreator: _onSave,
  effect: async (action, apis) => {
    apis.cancelActiveListeners();
    apis.dispatch(onCreateOrUpdateProduct(true));

    const { studio, authSlice: auth, printful } = apis.getState();
    const { printfulStoreIdSelector } = printfulSlice.getSelectors();

    const {
      canvasesFilesSelector,
      studioProductSelector,
      selectedMockupSelector,
      selectedCanvasSelector,
      selectedCanvasesViewsSelector,
      updatedProductIdSelector,
      variantsSelector,
    } = studioSlice.getSelectors();

    const { storeIdSelector } = authSlice.getSelectors();

    const canvasesFiles = canvasesFilesSelector(studio);
    const product = studioProductSelector(studio);
    const selectedMockup = selectedMockupSelector(studio);
    const selectedCanvases = selectedCanvasSelector(studio);
    const views = selectedCanvasesViewsSelector(studio);
    const updatedProductId = updatedProductIdSelector(studio);
    const variants = variantsSelector(studio);

    const printfulStoreId = printfulStoreIdSelector(printful);
    const storeId = storeIdSelector(auth);

    const imageBlob = await Utils.ImageUrlToBlob(selectedMockup?.mockup_url);
    const thumbnail_url = await Utils.blobToImage(imageBlob);

    const sync_product = {
      name: product.title,
      thumbnail: `https://airomnishopback.devssh.xyz${thumbnail_url}`,
    };

    const sync_variants = variants?.map((variant) => {
      return {
        variant_id: variant.id,
        retail_price:
          Number(variant.price) +
          views?.reduce((prev, next) => {
            return Number(prev) + Number(next.additional_price);
          }, 0),
        files: canvasesFiles
          ?.filter((file) => selectedCanvases.includes(file.type || file.title))
          ?.map((file) => ({ url: file.image_url, type: file.type })),
      };
    });

    // Step #1 -- create product on printful
    const { data: createProduct } = await client.mutate({
      mutation: studio?.isEdit ? PUT_PRINTFUL_REQ : POST_PRINTFUL_API,
      variables: {
        url: studio.isEdit
          ? `https://api.printful.com/store/products/@${updatedProductId}`
          : 'https://api.printful.com/store/products',
        body: JSON.stringify({ sync_product, sync_variants }),
      },
    });
    const printfulResponse = JSON.parse(
      createProduct[studio.isEdit ? 'runPrintfulPutRequest' : 'runPrintfulPostRequest']
    );

    // Step #2 -- get created product from printful
    const { data: getProduct } = await client.mutate({
      mutation: GET_PRINTFUL_API,
      variables: { url: `https://api.printful.com/store/products/${printfulResponse.result.id}` },
    });
    const savedProduct = JSON.parse(getProduct.runPrintfulGetRequest);

    // Step #2 -- get created product from printful
    const { data: pfStoreId } = await client.mutate({ mutation: GET_PRINTFULL_STORE_ID });

    // Step #3 -- update product in local db
    await client.mutate({
      mutation: studio.isEdit ? UPDATE_PRODUCT_LOCAL : SAVE_PRODUCT,
      variables: {
        storeId: pfStoreId.getPrintfulStoreId,
        printfulProductId: String(savedProduct?.result?.sync_product?.id),
      },
    });

    // Step #4 -- refetch product to display
    await client.refetchQueries({
      include: [{ query: GET_PRODUCTS, variables: { storeId } }],
    });

    apis.dispatch(onCreateOrUpdateProduct(false));
  },
});

export { pfProductSaverUpdaterListeners };
