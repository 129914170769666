export class Utils {
  static async ImageUrlToBlob(url) {
    try {
      const imageBlobResponse = await fetch('https://airomnishopback.devssh.xyz/view-printful-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });
      const imageBlob = await imageBlobResponse.blob();
      console.log({ imageBlob });
      return imageBlob;
    } catch (error) {
      console.error({ error });
    }
  }

  static async blobToImage(blob) {
    try {
      const formData = new FormData();
      formData.append('media', blob, 'thumbnail.png');
      const response = await fetch('https://airomnishopback.devssh.xyz/upload-media', {
        method: 'POST',
        body: formData,
      });
      const jsonRes = await response.json();
      return jsonRes.data[0].name;
    } catch (error) {
      console.error({ error });
    }
  }
}
