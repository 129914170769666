import { createListenerMiddleware } from '@reduxjs/toolkit';
import { setPfProduct } from '../slices/pf_product';
import { getPrintFulStoreId, setPrintfulStoreId, getPrintFulStore } from '../slices/printful';

const printfulProductsListeners = createListenerMiddleware();

printfulProductsListeners.startListening({
  actionCreator: setPfProduct,
  effect: (action, apis) => {
    apis.cancelActiveListeners();
    apis.dispatch(getPrintFulStoreId());
  },
});
printfulProductsListeners.startListening({
  actionCreator: setPrintfulStoreId,
  effect: (action, apis) => {
    apis.cancelActiveListeners();
    apis.dispatch(getPrintFulStore(action.payload));
  },
});

export { printfulProductsListeners };
