import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    user: null,
    userInfo: null,
  },
  reducers: {
    onAuthenticated(state, action) {
      state.user = action.payload;
    },
    onUnAuthenticated(state, action) {
      state.user = null;
    },
    onPrintFulAuthenticated(state, action) {
      state.user = { ...(state.user || {}), printfulToken: action.payload };
    },
    onPrintFulStoreConnected(state, action) {
      state.user = { ...(state.user || {}), printfulStroeId: action.payload };
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
  },
  selectors: {
    authenticatedUserSelector(state) {
      return state.user;
    },
    printFulTokenSelector(state) {
      return state?.user?.printfulToken;
    },
    storeIdSelector(state) {
      return state?.user?.user?.store?.id;
    },
    userInfoSelector(state) {
      return state.userInfo;
    },
  },
});

export const { authenticatedUserSelector, printFulTokenSelector, storeIdSelector } = authSlice.selectors;
export const { onAuthenticated, onUnAuthenticated, onPrintFulAuthenticated, onPrintFulStoreConnected } =
  authSlice.actions;
