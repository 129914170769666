import { gql } from '@apollo/client';

export const GET_AUTH_URL = gql`
  mutation GetPrintfullAuthUrl($successUrl: String!) {
    getPrintfullAuthUrl(successUrl: $successUrl)
  }
`;
export const GET_AUTH_PRINTFUL_AUTHORZIE = gql`
  mutation AuthenticatePrintfulWithAuthorizationCode($authorizationCode: String!, $storeId: String!) {
    authenticatePrintfulWithAuthorizationCode(authorizationCode: $authorizationCode, storeId: $storeId)
  }
`;

export const GET_PRINTFUL_TOKEN = gql`
  mutation Mutation {
    getAccessToken
  }
`;

export const GET_PRINTFUL_API = gql`
  mutation RunPrintfulGetRequest($url: String!) {
    runPrintfulGetRequest(url: $url)
  }
`;

export const POST_PRINTFUL_API = gql`
  mutation RunPrintfulPostRequest($body: String!, $url: String!) {
    runPrintfulPostRequest(body: $body, url: $url)
  }
`;

export const PUT_PRINTFUL_REQ = gql`
  mutation RunPrintfulPutRequest($url: String!, $body: String!) {
    runPrintfulPutRequest(url: $url, body: $body)
  }
`;

export const SAVE_PRODUCT = gql`
  mutation ($storeId: String!, $printfulProductId: String!) {
    saveProduct(storeId: $storeId, printfulProductId: $printfulProductId)
  }
`;
export const UPDATE_PRODUCT_LOCAL = gql`
  mutation UpdateSyncProduct($storeId: String!, $printfulProductId: String!) {
    updateSyncProduct(storeId: $storeId, printfulProductId: $printfulProductId)
  }
`;
export const GET_PRINTFULL_STORE_ID = gql`
  mutation {
    getPrintfulStoreId
  }
`;
