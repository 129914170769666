import { toast } from 'react-toastify';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { generateProductMockups, setDesignOrMockup, slice as studioSlice } from '../slices/studio';
import { store } from '../store';

const designMockupsLiners = createListenerMiddleware();

designMockupsLiners.startListening({
  actionCreator: setDesignOrMockup,
  effect: (action, apis) => {
    apis.cancelActiveListeners();

    const state = apis?.getState();
    const selectedCanvases = studioSlice.getSelectors().selectedCanvasSelector(state?.studio);

    if (action.payload === 'mockups' && !selectedCanvases?.length) {
      apis.dispatch(setDesignOrMockup('design'));
      toast.error('At least one design is required. Please select your design.');
      return;
    }

    const canvases = studioSlice.getSelectors().canvasesSelector(state?.studio);
    const productId = studioSlice.getSelectors().productIdSelector(state?.studio);
    const variantsIds = studioSlice.getSelectors().variantsIdsSelector(state?.studio);

    apis.dispatch(generateProductMockups({ canvases, productId, variantsIds }));
  },
});

export { designMockupsLiners };
