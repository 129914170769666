import { List } from '@mui/material';

import { selectedViewSelector, shapesSelector } from '../../../redux/slices/studio';
import { useSelector } from '../../../redux/store';
import { EditShapePanel } from './EditShapePanel';

export default function LayersSection() {
  const shapes = useSelector(shapesSelector);
  const view = useSelector(selectedViewSelector);

  return (
    <List sx={{ height: 'calc(100vh - 160px)', overflowY: 'auto', overflowX: 'hidden' }}>
      {shapes[view.type]?.map((shape) => (
        <EditShapePanel shape={shape} />
      ))}
    </List>
  );
}
