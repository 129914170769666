import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './utils/highlight';
// scroll bar

import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-quill/dist/quill.snow.css';
// lightbox css
import 'react-image-lightbox/style.css';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
// contexts
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
// redux
import { store, persistor } from './redux/store';

import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { client } from './graphql/Server';

// ----------------------------------------------------------------------
const root = document.getElementById('root');

ReactDOM.createRoot(root).render(
  <ApolloProvider client={client}>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <HelmetProvider>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </HelmetProvider>
        </AuthProvider>
      </PersistGate>
    </ReduxProvider>
  </ApolloProvider>
);
