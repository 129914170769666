import { createSlice } from '@reduxjs/toolkit';
import { GET_PRINTFUL_API, GET_PRINTFULL_STORE_ID } from '../../graphql/printful';
import { client } from '../../graphql/Server';
import { dispatch } from '../store';

export const printful = createSlice({
  name: 'printful',
  initialState: {
    storeId: null,
    store: null,
  },
  reducers: {
    setPrintfulStoreId(state, action) {
      state.storeId = action.payload;
    },
    setPrintfulStore(state, action) {
      state.store = action.payload;
    },
  },
  selectors: {
    printfulStoreIdSelector(state) {
      return state?.storeId;
    },
    printfulStoreSelector(state) {
      return state?.store;
    },
  },
});

export const { printfulStoreIdSelector, printfulStoreSelector } = printful.selectors;
export const { setPrintfulStoreId, setPrintfulStore } = printful.actions;

export function getPrintFulStoreId() {
  return async () => {
    try {
      const response = await client.mutate({
        mutation: GET_PRINTFULL_STORE_ID,
      });
      dispatch(setPrintfulStoreId(response.data.getPrintfulStoreId));
    } catch (error) {
      console.error(error);
      dispatch(setPrintfulStoreId(null));
    }
  };
}

export function getPrintFulStore(storeId) {
  return async () => {
    try {
      const response = await client.mutate({
        mutation: GET_PRINTFUL_API,
        variables: { url: `https://api.printful.com/stores/${storeId}` },
      });
      const store = JSON.parse(response.data.runPrintfulGetRequest);
      dispatch(setPrintfulStore(store?.result));
    } catch (error) {
      console.error(error);
      dispatch(setPrintfulStore(null));
    }
  };
}
