import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phone: String
    $countryCode: String
    $country: String
    $profilePic: String
    $dob: String
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phone: $phone
      countryCode: $countryCode
      country: $country
      profilePic: $profilePic
      dob: $dob
    ) {
      lastName
      id
      firstName
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $addressId: Int!
    $street: String!
    $city: String!
    $state: String!
    $country: String!
    $street2: String
    $lon: String
    $zipCode: String
    $lat: String
  ) {
    updateUser(
      addressId: $addressId
      street: $street
      city: $city
      state: $state
      country: $country
      street2: $street2
      lon: $lon
      zipCode: $zipCode
      lat: $lat
    ) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteAdmin($adminId: Int!) {
    deleteAdmin(adminId: $adminId)
  }
`;

export const GET_USER_BY_ID = gql`
  query UserById($userId: Int!) {
    userById(userId: $userId) {
      id
      role
      firstName
      lastName
      profilePic
      dob
      email
      emailVerifiedAt
      country
      countryCode
      phone
      phoneVerifiedAt
      createdAt
    }
  }
`;

export const GET_ALL_USER = gql`
  query Users {
    users {
      country
      countryCode
      createdAt
      dob
      email
      emailVerifiedAt
      firstName
      id
      lastName
      phone
      phoneVerifiedAt
      profilePic
      role
    }
  }
`;
export const ME = gql`
  query Me {
    me {
      id
      role
      firstName
      lastName
      profilePic
      dob
      email
      emailVerifiedAt
      country
      countryCode
      phone
      phoneVerifiedAt
      createdAt

      store {
        id
        name
        coverPic
        description
        Address {
          id

          country
          zipCode
          countryCode
          phone
          personName
          lon
          lat
          createdAt
        }

        subscriptions {
          id
          isActive
          expiredAt
          Package {
            id
            name
            price
          }
        }
      }
    }
  }
`;
