import { createListenerMiddleware } from '@reduxjs/toolkit';
import { client } from '../../graphql/Server';
import { GET_PRINTFUL_API } from '../../graphql/printful';
import { setProductId, setUpdateProductId, slice as studioSlice } from '../slices/studio';

const getStudioProduct = createListenerMiddleware();

getStudioProduct.startListening({
  actionCreator: setProductId,
  effect: async (action, apis) => {
    apis.cancelActiveListeners();

    if (!action.payload) return;

    apis.dispatch(studioSlice.actions.startLoading());

    try {
      const { data: { runPrintfulGetRequest } = { runPrintfulGetRequest: '' } } = await client.mutate({
        mutation: GET_PRINTFUL_API,
        variables: { url: `https://api.printful.com/products/${action.payload}` },
      });
      const jsonRes = await JSON.parse(runPrintfulGetRequest);
      apis.dispatch(studioSlice.actions.setProduct(jsonRes?.result?.product));
      apis.dispatch(studioSlice.actions.setVariants(jsonRes?.result?.variants));
      apis.dispatch(studioSlice.actions.setColors(jsonRes?.result?.variants));
      apis.dispatch(studioSlice.actions.setSizes(jsonRes?.result?.variants));
      apis.dispatch(studioSlice.actions.setViews(jsonRes?.result?.product?.files));
      apis.dispatch(studioSlice.actions.setSelectedView(jsonRes?.result?.product?.files?.[0]));
      apis.dispatch(studioSlice.actions.setVariant(jsonRes?.result?.variants?.[0]));
      apis.dispatch(studioSlice.actions.openStudio());
    } catch (error) {
      console.error(error);
      apis.dispatch(studioSlice.actions.hasError(error));
    }
  },
});

getStudioProduct.startListening({
  actionCreator: setUpdateProductId,
  effect: async (action, apis) => {
    try {
      const { data: { runPrintfulGetRequest } = { runPrintfulGetRequest: '' } } = await client.mutate({
        mutation: GET_PRINTFUL_API,
        variables: { url: `https://api.printful.com/store/products/@${action.payload}` },
      });

      const jsonRes = await JSON.parse(runPrintfulGetRequest);
      const id = jsonRes?.result?.sync_variants?.[0]?.product?.product_id;

      apis.dispatch(setProductId(id));
    } catch (error) {
      console.error(error);
    }
  },
});

export { getStudioProduct };
