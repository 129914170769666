import { Box } from '@mui/material';
import { originalColorsSelector, setActiveColor } from '../../../redux/slices/studio';
import { useDispatch, useSelector } from '../../../redux/store';

export default function VariantsColors() {
  const dispatch = useDispatch();
  const colors = useSelector(originalColorsSelector);
  return (
    <Box display="flex" gap={1} padding={2} flexWrap="wrap">
      {colors?.map((color) => (
        <Box
          key={color.color}
          sx={{
            position: 'relative',
            backgroundColor: color.color,
            width: 30,
            height: 30,
            borderRadius: 1,
            border: '2px solid black',
            padding: 1,
            cursor: 'pointer',
            '&::before': color.isSelected
              ? {
                  content: '""',
                  position: 'absolute',
                  top: -4,
                  left: -4,
                  width: 'calc(100% + 8px)',
                  height: 'calc(100% + 8px)',
                  borderRadius: 'inherit',
                  border: '2px solid #6b7280',
                  pointerEvents: 'none',
                  zIndex: -1,
                }
              : {},
          }}
          onClick={() => dispatch(setActiveColor(color.color))}
        />
      ))}
    </Box>
  );
}
